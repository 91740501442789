import React, { useEffect, useState } from "react";
import classes from "./Profile.module.css";
import OverviewTab from "../../components/profile/OverviewTab/OverviewTab";
import OrderTab from "../../components/profile/OrderTab/OrderTab";
import ProfileDetailsTab from "../../components/profile/ProfileDetailsTab/ProfileDetailsTab";
import AddressesTab from "../../components/profile/AddressesTab/AddressesTab";
import DeleteAccount from "../../components/profile/DeleteAccount/DeleteAccount";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoginPath, PrivacyPolicyPath, TermsPath } from "../../constant/constant";
import SaveCardsTab from "../../components/profile/SaveCardsTab/SaveCardsTab";
import { useMobileScreen } from "../../utils/isMobileScreen";
import { Accordion } from "react-bootstrap";
import { userDetailsStore } from "../../store/GlobalUserData";
import { getUserId } from "../../utils/getUserId";
import { Helmet } from "react-helmet";
import CouponsPage from "../../components/cart/CartSteps/CouponsPage";
import GiftCertificate from "../../components/cart/CartSteps/GiftCertificate";

function Profile() {
  const [ searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(parseInt(searchParams.get("activeTab"))? parseInt(searchParams.get("activeTab")) : 1);
  const navigate = useNavigate();
  const { userDetails } = userDetailsStore();
  const userId= getUserId() 
  let screen = useMobileScreen();
  const location = useLocation()

  useEffect(()=>{
     if(isNaN(userId)){
      navigate(LoginPath, {state:{from: location.pathname + location.search}});
      localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
     }

  },[userId])
  
  useEffect(()=>{
    searchParams.get("activeTab") && setActiveTab(parseInt(searchParams.get("activeTab")))
  },[searchParams.get("activeTab")])

  const buttonData = [
    {
      id: 1,
      title: "Overview",
      component: <OverviewTab setActiveTab={setActiveTab} />,
    },
    {
      id: 2,
      title: "Orders & Returns",
      component: <OrderTab />,
    },
    {
      id: 3,
      title: "Coupons",
      component: <CouponsPage />,
    },
    {
      id: 4,
      title: "Profile",
      component: <ProfileDetailsTab />,
    },
    // {
    //   id: 5,
    //   title: "Saved Cards",
    //   component: <SaveCardsTab />,
    // },
    // {
    //   id: 6,
    //   title: "Saved UPI",
    //   component: <SaveCardsTab />,
    // },
    // {
    //   id: 7,
    //   title: "Saved Wallets/BNPL",
    //   component: <SaveCardsTab />,
    // },
    {
      id: 8,
      title: "Addresses",
      component: <AddressesTab />,
    },
    {
      id: 9,
      title: "Delete Account",
      component: <DeleteAccount />,
    },
    // {
    //   id: 10,
    //   title: "Gift Certificates",
    //   component: <GiftCertificate />,
    // },
  ];
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Account - Upvan Fashionn</title>
        <meta name="description" content="Account - Upvan Fashion" />
      </Helmet>
      <div className={classes.userName}>
        <p className={classes.heading}>Account:</p>
        <p className={classes.name}>
          {userDetails?.first_name} {userDetails?.last_name}
        </p>
      </div>
      {screen ? (
        <div className="profileAccordion">
          <Accordion
            defaultActiveKey={JSON.stringify(activeTab)}
            activeKey={JSON.stringify(activeTab)}
          >
            {buttonData.map((item, index) => (
              <Accordion.Item eventKey={JSON.stringify(item.id)} key={index}>
                <Accordion.Header onClick={(e) => activeTab === item.id ? setActiveTab("") : setActiveTab(item.id)}>
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>{item.component}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      ) : (
        <div className={classes.tabContainer}>
          <div className={classes.tab}>
            {buttonData.map((item, index) => (
              <button
                className={classes.tablinks}
                key={index}
                onClick={() => setActiveTab(item.id)}
                style={{
                  color: item.id === activeTab ? "#eb2675" : "#000",
                  fontWeight: item.id === activeTab && 600,
                  borderBottom:
                    item.id === activeTab
                      ? "1px solid #eb2675"
                      : "1px solid #dbdbdb",
                }}
              >
                {item.title}
              </button>
            ))}
            <button
              className={classes.tablinks}
              onClick={() => navigate(TermsPath)}
            >
              Terms of Use
            </button>
            <button
              className={classes.tablinks}
              onClick={() => navigate(PrivacyPolicyPath)}
            >
              Privacy Policy
            </button>
          </div>
          {activeTab == 1 && <OverviewTab setActiveTab={setActiveTab} />}
          {activeTab == 2 && <OrderTab />}
          {activeTab == 3 && <CouponsPage />}
          {activeTab == 4 && <ProfileDetailsTab />}
          {activeTab == 5 && <SaveCardsTab />}
          {activeTab == 8 && <AddressesTab />}
          {activeTab == 9 && <DeleteAccount />}
          {/* {activeTab == 10 && <GiftCertificate/>} */}
        </div>
      )}
    </div>
  );
}

export default Profile;
