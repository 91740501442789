import BlogDetails from "../components/blogDetails/BlogDetails";
import Help from "../pages/Help/Help";
import Products from "../components/products/Products";
import AllBlogs from "../pages/AllBlogs";
import Home from "../pages/Home/HomeNew";
import Login from "../pages/Login/Login";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "../pages/Profile/Profile";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Signup from "../pages/Signup/Signup";
import ContactUs from "../pages/ContactUs/ContactUs";
import AboutUs from "../pages/AboutUs/AboutUs";
import Terms from "../pages/Terms/Terms";
import UserRegister from "../pages/UserRegister";
import WishList from "../pages/WishList/WishList";
import Cart from "../pages/Cart/Cart";
import CheckoutPage from "../pages/Cart/CheckoutPage";
import ProductDetails from "../pages/productdetails/ProductDetails";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

export const HomePath = "/";
export const HelpPath = "/help";
export const BlogsPath = "/blogs";
export const BlogDetailsPath = "/blog-details/:blog_id";
export const ProductsPath = "/products";
export const ProductDetailsPath = "/product_details/:product_id";
export const UserRegisterPath = "/user_register";
export const LoginPath = "/login";
export const SignupPath = "/signup";
export const WishListPath = "/wishlist";
export const NewArrivals = '/new_arrivals'
export const BestSelling = '/best_selling'
export const ProfilePath = "/profile";
export const TermsPath = "/terms-of-use";
export const PrivacyPolicyPath = "/privacy-policy";
export const ResetPasswordPath = "/reset-password";
export const AboutUsPath = "/about-us";
export const ContactUsPath = "/contact-us";
export const CartPath = "/cart";
export const CheckoutPath = "/checkout";
export const PageNotFoundPath = "*";

export const RouteConstant = [
  {
    path: HomePath,
    component: <Home />,
  },
  {
    path: ContactUsPath,
    component: <ContactUs />,
  },
  {
    path: CartPath,
    component: <Cart />,
  },
  {
    path: CheckoutPath,
    component: <CheckoutPage />,
  },
  {
    path: HelpPath,
    component: <Help />,
  },
  {
    path: BlogDetailsPath,
    component: <BlogDetails />,
  },
  {
    path: BlogsPath,
    component: <AllBlogs />,
  },
  {
    path: ProductsPath,
    component: <Products />,
  },
  {
    path: ProductDetailsPath,
    component: <ProductDetails />,
  },
  {
    path: UserRegisterPath,
    component: <UserRegister />,
  },
  {
    path: AboutUsPath,
    component: <AboutUs />,
  },
  {
    path: LoginPath,
    component: <Login />,
  },
  {
    path: ResetPasswordPath,
    component: <ResetPassword />,
  },
  {
    path: SignupPath,
    component: <Signup />,
  },
  {
    path: NewArrivals,
    component: <Products />,
  },
  {
    path: BestSelling,
    component: <Products />,
  },
  {
    path: WishListPath,
    component: <WishList />,
  },
  {
    path: ProfilePath,
    component: <Profile />,
  },
  {
    path: TermsPath,
    component: <Terms />,
  },
  {
    path: PrivacyPolicyPath,
    component: <PrivacyPolicy />,
  },
  {
    path: PageNotFoundPath,
    component: <PageNotFound />,
  },
];

export const termsData = [
  {
    title: "Acceptance of Terms",
    description:
      "<p>By accessing or using Upvan Fashion, you agree to comply withand be bound by these Terms of Use. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</p>",
  },
  {
    title: "Use License",
    description:
      "<p>Permission is granted to temporarily download one copy of thematerials (information or software) on Upvan Fashion for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:</p><ul> <li>Modify or copy the materials.</li><li>Use the materials for any commercial purpose or for any public display (commercial or non-commercial).</li><li> Attempt to decompile or reverse engineer any software contained on Upvan Fashion.</li><li>Remove any copyright or other proprietary notations from the materials.</li></ul>",
  },
  {
    title: "Disclaimer",
    description:
      "<p>The materials on Upvan Fashion are provided on an 'as is' basis. Upvan Fashion makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>",
  },
  {
    title: "Limitations",
    description:
      "<p>In no event shall Upvan Fashion or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Upvan Fashion, even if Upvan Fashion or a Upvan Fashion authorized representative has been notified orally or in writing of the possibility of such damage.</p>",
  },
  {
    title: "Revisions and Errata",
    description:
      "<p>The materials appearing on Upvan Fashion could include technical, typographical, or photographic errors. Upvan Fashion does not warrant that any of the materials on its website are accurate, complete, or current.</p>",
  },
  {
    title: "Links",
    description:
      "<p>Upvan Fashion has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Upvan Fashion. Use of any such linked website is at the user's own risk.</p>",
  },
  {
    title: "Site Terms of Use Modifications",
    description:
      "<p>Upvan Fashion may revise these terms of use for its website at any time without notice. By using this website, you are agreeing to be bound by the then current version of these Terms of Use.</p>",
  },
  {
    title: "Governing Law",
    description:
      "<p>Any claim relating to Upvan Fashion shall be governed by the laws of India without regard to its conflict of law provisions.</p>",
  },
  {
    title: "Contact Information",
    description:
      "<p>If you have any questions about these Terms of Use, please contact us at [Your Contact Information].</p>",
  },
];

export const privacyPolicyData = [
  {
    title: "Personal Information We Collect",
    description:
      '<p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information".</p>',
  },
  {
    title: "How We Use Your Personal Information",
    description:
      "<p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>",
  },
  {
    title: "Sharing Your Personal Information",
    description:
      "<p>We may share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site.</p>",
  },
  {
    title: "Your Rights",
    description:
      "<p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>",
  },
  {
    title: "Data Retention",
    description:
      "<p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>",
  },
  {
    title: "Changes",
    description:
      "<p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>",
  },
  {
    title: "Contact Us",
    description:
      "<p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at [Your Contact Information].</p>",
  },
];
export const constantsForSortingProducts = [
  { name: "Default Sorting", value: "default" },
  { name: "Sort by popularity", value: "popularity" },
  { name: "Sort by average rating", value: "average-rating" },
  { name: "Sort by newness", value: "newness" },
  { name: "Sort by price: low to high", value: "low-to-high" },
  { name: "Sort by price: high to low", value: "high-to-low" },
];

export const navbarData = [
  { title: "HOME", redirectURL: HomePath, subMenu: [] ,activeKey: HomePath},
  { title: "NEW ARRIVALS", redirectURL: NewArrivals, subMenu: [] ,activeKey: NewArrivals},
  {
    title: "CLOTHING",
    redirectURL: "",
    activeKey: ProductsPath,
    subMenu: [
      { subTitle: "All Clothing", subRedirectURL: ProductsPath },
      { subTitle: "Shirts, Tops & Tees", subRedirectURL: ProductsPath },
      { subTitle: "Co-ords, Dresses & Skirts", subRedirectURL: ProductsPath },
      { subTitle: "Suits & Blazers", subRedirectURL: ProductsPath },
      { subTitle: "Hoodies & Sweatshirts", subRedirectURL: ProductsPath },
      { subTitle: "Jackets & Sweaters", subRedirectURL: ProductsPath },
      { subTitle: "Jeans & Cargos", subRedirectURL: ProductsPath },
      { subTitle: "Trousers & Capris", subRedirectURL: ProductsPath },
      { subTitle: "Leggings & Jeggings", subRedirectURL: ProductsPath },
      { subTitle: "Playsuits & Jumpsuits", subRedirectURL: ProductsPath },
      { subTitle: "Skirts & Shorts", subRedirectURL: ProductsPath },
      { subTitle: "Kurtis", subRedirectURL: ProductsPath },
      { subTitle: "Sportswear", subRedirectURL: ProductsPath },
      { subTitle: "Shrugs", subRedirectURL: ProductsPath },
      { subTitle: "Designer Blouse", subRedirectURL: ProductsPath },
    ],
  },
  { title: "BEST SELLER", redirectURL: BestSelling, subMenu: [],activeKey: BestSelling },
  { title: "HELP", redirectURL: HelpPath, subMenu: [],activeKey: HelpPath },
  { title: "BLOG", redirectURL: BlogsPath, subMenu: [],activeKey: BlogsPath },
];

export const shippingFaqData = [
  {
    title: "How long will the delivery take?",
    description:
      "It's really quick! We strive to deliver all our orders / products in the fastest time possible. Depending upon your address, the delivery time may vary between 2 - 9 working days. That still won't stop us from trying to get you your order faster. On each product's display page, you can enter your location's pin code to check when you can expect to receive the delivery.",
  },
  {
    title: "Can I cancel my order?",
    description:
      "Yes! You can cancel an order as long as we havent packed it for you. As soon as the order is cancelled, we refund the entire amount paid for the order.",
  },
  {
    title: "What are the various modes of payment at Upvan?",
    description:
      "You can pay using following methods of Payment: 1. Credit Card 2. Debit Card 3. Net Banking 4.  E-Wallets (Paytm, MobiKwik, Freecharge and more) 5. Cash on Delivery 6. Upvan Wallet Note: All payment methods depend on your pin code, so please ensure that you check your pin code to find out the available methods of payment over there.",
  },
  {
    title: "How do I return my product?",
    description:
      "Super easy! Please login with your credentials and visit the My Account section. Select the order you want to return and click on Return/Exchange and follow the instructions on this page to obtain a Return ID.You can also view Return Creation Demo under My Account section on your phone. If you are using a PC/laptop, copy paste this link on your browser to view the Upvan Fashion",
  },
];
export const cancellationFaqData = [
  {
    title: "How can I track my order?",
    description:
      "You can track your order as soon as it is shipped out. To know the status of your item real time, just tap on the tracking link in the email sent to you. Alternatively, you can also login to Upvan.com and visit the ‘My Account' section to know your order status.",
  },
  {
    title: "What are the steps to be followed to cancel my order?",
    description:
      'Please log in to your Upvan account. Click on My Account section at  the top right corner of your screen3. Select the order/item you want to cancel 4. Locate the "Cancel" button and click on it 5. Voila! The order has been cancelled.',
  },
  {
    title: "What does convenience fee mean?",
    description:
      "Convenience fee is a combination of COD charges (if applicable), Platform Fee (to have hassle free shopping experience), and delivery fees. Convenience fee is non refundable.  Reliance reserves the right to extend, cancel, discontinue, prematurely withdraw, change, alter or modify the Convenience fee, COD charges, Platform Fee, Shipping Charges or Delivery Fee at their sole discretion at any time as may be required including in view of business exigencies and/or changes by a regulatory authority and/or statutory changes and/or any reasons beyond their control and the same shall be binding on the Upvan Customer(s) without prior notice.",
  },
  {
    title: "What is your returns policy?",
    description:
      "At Upvan, we have a very flexible returns policy which is absolutely customer friendly. If you do not find the product(s) satisfying, you can return it as long as the following conditions are met: It should be unused. You can wear it to check for fit and comfort, making sure that any smell does not transfer to the product (natural body odour, body deodorant, perfume etc.). It should be unwashed. We don't want you wasting any detergent! ☺. The price tags, brand tags, shoe-box and all original packaging must be present . The product must be returned within 15 days (*10 days of return window, for items displayed as part of WinterSale). Any gifts received must also be returned along with the product. Toys will have 10 days return/replacement policy in case you have received a damaged, defective, wrong product or product with missing accessory.",
  },
];

export const helpData = [
  {
    id: 1,
    title: "Shipping FAQs",
    data: shippingFaqData,
  },
  {
    id: 2,
    title: "Cancellation FAQs",
    data: cancellationFaqData,
  },
  {
    id: 3,
    title: "Payment FAQs",
    data: "",
  },
  {
    id: 4,
    title: "Returns FAQs",
    data: "",
  },
  {
    id: 5,
    title: "Upvan Wallet",
    data: "",
  },
  {
    id: 6,
    title: "Account & Shopping",
    data: "",
  },
  {
    id: 7,
    title: "Delete Account",
    data: "",
  },
  {
    id: 8,
    title: "Gift Card",
    data: "",
  },
];
export const couponTypes = [
  {type:'', text: 'Saves'}
]
export const profileNavbarData = {
  partOne: [
    { title: "Orders", redirectURL: ProfilePath, activeTab: 2 },
    { title: "Wishlist", redirectURL: WishListPath, activeTab: 2 },
    { title: "Contact Us", redirectURL: ContactUsPath, activeTab: 4 },
    { title: "Coupons", redirectURL: ProfilePath, activeTab: 3 },
  ],
  partTwo: [
    // { title: "Saved Cards", redirectURL: ProfilePath, activeTab: 5 },
    { title: "Saved Addresses", redirectURL: ProfilePath, activeTab: 8 },
  ],
  partThree: [
    { title: "Edit Profile", redirectURL: ProfilePath, activeTab: 4 },
    { title: "Logout", redirectURL: "" },
  ],
  partFour: [
    { title: "Indian Rupees", value: "INR" },
    { title: "US Dollars", value: "USD" }
  ]
};

export const cartSteps = [
  {
    id: 1,
    title: "Address",
    icon: "pe-7s-map-marker",
  },
  {
    id: 2,
    title: "Review",
    icon: "pe-7s-like2",
  },
  {
    id: 3,
    title: "Payment",
    icon: "pe-7s-cash",
  },
];

export const footerData = [
  {
    title: "Clothing Categories",
    listData: [
      {
        listTitle: "All Clothing",
        listURL: ProductsPath,
      },
      { listTitle: "Shirts, Tops & Tees", 
        listURL: `${ProductsPath}?category=${encodeURIComponent("Shirts, Tops & Tees")}` 
      },
      { listTitle: "Co-ords, Dresses & Skirts", 
        listURL: `${ProductsPath}?category=${encodeURIComponent("Co-ords, Dresses & Skirts")}` 
      },
      { listTitle: "Suits & Blazers", 
        listURL: `${ProductsPath}?category=${encodeURIComponent("Suits & Blazers")}` 
      },
      { listTitle: "Hoodies & Sweatshirts", 
        listURL: `${ProductsPath}?category=${encodeURIComponent("Hoodies & Sweatshirts")}` 
      },
    ]
  },
  {
    title: "Quick Links",
    listData: [
      {
        listTitle: "Home",
        listURL: HomePath,
      },
      {
        listTitle: "New Arrivals",
        listURL: NewArrivals,
      },
      {
        listTitle: "Best Seller",
        listURL: BestSelling,
      },
      {
        listTitle: "Help",
        listURL: HelpPath,
      },
      {
        listTitle: "Blog",
        listURL: BlogsPath,
      },
    ],
  },
  {
    title: "Customer Services",
    listData: [
      {
        listTitle: "Orders",
        listURL: `${ProfilePath}/?activeTab=2`,
      },
      {
        listTitle: "Wishlist",
        listURL: WishListPath,
      },
      {
        listTitle: "Contact Us",
        listURL: ContactUsPath,
      },
      {
        listTitle: "Coupons",
        listURL: `${ProfilePath}/?activeTab=3`,
      },
      {
        listTitle: "Save Addresses",
        listURL: `${ProfilePath}/?activeTab=8`,
      },
    ],
  },
  {
    title: "Site Terms",
    listData: [
      {
        listTitle: "Terms of Use",
        listURL: TermsPath,
      },
      {
        listTitle: "Privacy Policy",
        listURL: PrivacyPolicyPath,
      },
    ],
  },
];

export const footerIcon = [
  {
    icon: <FaFacebookF />,
    redirectUrl: "",
  },
  {
    icon: <FaTwitter />,
    redirectUrl: "",
  },
  {
    icon: <FaLinkedinIn />,
    redirectUrl: "",
  },
  {
    icon: <FaInstagram />,
    redirectUrl: "",
  },
];