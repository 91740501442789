import React, { Fragment, useEffect, useState } from 'react';
import classes from './HomeNew.module.css';
import { Carousel, Container, Row } from 'react-bootstrap';
import { getBigComToken, getUserId } from '../../utils/getUserId';
import axios from 'axios';
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from 'react-icons/ti';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useCountStore, useCurrencyCode } from '../../store/CountStore';
// import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import { LoginPath, ProductsPath } from '../../constant/constant';
import _, { isEmpty } from 'lodash';
import CustomersReview from '../../components/Home/CustomersReview/CustomersReview';
import { useMobileScreen } from '../../utils/isMobileScreen';
// import Offerbanner from '../../components/Home/Offerbanner/Offerbanner';
// import AdBanner from '../../components/Home/AdBanner/AdBanner';

const HomeNew = () => {
    const [popularProducts, setPopularProducts]=useState([])
    const [bestSelling, setBestSelling]=useState([])
    const BigComToken = getBigComToken()
    const {count} = useCountStore()
    const navigator = useNavigate()
    const { updateCount , updateWishListIds, WishListIds } = useCountStore();
    const { defaultCurrency } = useCurrencyCode()
    const customerId = getUserId()
    const location = useLocation()
    const smallScreen = useMobileScreen();
    const [catgeoriesBlockData,setCatgeoriesBlockData] = useState([
      {
        name:"Shirts",
        title:"Shirts, Tops & Tees",
        image:"../../images/exclusive-tops.jpg"
      },
      {
        name:"Co-ords",
        title:"Co-ords, Dresses & Skirts",
        image:"../../images/jeans.jpg"
      },
      {
        name:"Blazers",
        title:"Suits & Blazers",
        image:"../../images/dress.jpg"
      },
      {
        name:"Hoodies",
        title:"Hoodies & Sweatshirts",
        image:"../../images/sweater.jpg"
      },
    ])
 
    const addWishlistHandler = async (id, data) => {
        let variant_id = data.edges?.[0]?.node?.entityId;
        await axios
          .post(`${process.env.REACT_APP_API_PATH}wishlist`, {
            customer_id: customerId,
            product_id: id,
            variant_id:variant_id
          })
          .then(() => {
            fetchWishlistProductData();
            // showSuccessToast("Product Added to Wishlist!");
          })
          .catch((err) =>{
             console.log(err?.response?.data?.error)});
      };
      
      const fetchWishlistProductData = async () => {
        await axios
          .get(`${process.env.REACT_APP_API_PATH}wishlist?id=${customerId}`)
          .then((res) => {
            let ids = res.data?.map((ele) => ele.items[0].product_id);
            updateWishListIds(ids);
            updateCount(ids?.length);
            if (ids.length > 0) {
              axios
                .get(
                  `${
                    process.env.REACT_APP_API_PATH
                  }products?Details=${JSON.stringify({
                    productId: ids.join(","),
                  })}`
                )
                .then((resData) => {
                  updateCount(resData?.data?.data?.length);
                })
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => console.log(err));
      };

    const getBestSellingProducts = async (
      ) => {
        const details = {
          selectedCategories : []
        };
     
    await axios
          .post(
            `${
              process.env.REACT_APP_API_PATH
            }getProductsByFilter`,{ details:details,sort:"BEST_SELLING",limit:4, currencyCode: defaultCurrency }, {
              headers: {
                Authorization: 'Bearer ' + BigComToken
              }
            }
          )
          .then((res) => {
            const responseToCheck =
              res.data.data.site.search.searchProducts.products;
              const transformData = (response) => {
                return response.edges.map((edge) => {
                  const node = edge.node;
                  return {
                    ...node,
                    id: node.entityId,
                    name: node.name,
                    price: node.prices.price.value,
                    reviews_rating_sum: node.reviews.edges.reduce(
                      (sum, review) => sum + review.node.rating,
                      0
                    ),
                    pricecurrencyCode: node.prices.price.currencyCode,
                    options: node.productOptions.edges.map((optionEdge) => ({
                      ...optionEdge,
                      product_id: node.entityId,
                      name: optionEdge.node.displayName,
                      display_name: optionEdge.node.displayName,
                      type:
                        optionEdge.node.displayName === "Color"
                          ? "radio_buttons"
                          : "dropdown",
                      option_values: optionEdge.node.values.edges.map(
                        (valueEdge) => ({
                          id: valueEdge.node.entityId,
                          label: valueEdge.node.label,
                          value_data: null,
                          is_default: false,
                        })
                      ),
                    })),
                    reviews_count: node.reviews.edges.length,
                    images: node.images.edges.map((image) => ({
                      id: image.node.entityId,
                      url_thumbnail: image.node.urlOriginal,
                    })),
                  };
                });
              };
            const transformedData = transformData(responseToCheck);
            setBestSelling(transformedData);
          })
          .catch((er) => {
            console.error(er);
            setBestSelling([]);
          });
      };

      const getPopularProducts = async (
      ) => {
        const details = {
          selectedCategories : []
        };
     
    await axios
          .post(
            `${
              process.env.REACT_APP_API_PATH
            }getProductsByFilter`, { details:details,sort:"BEST_REVIEWED", limit:4, currencyCode: defaultCurrency }, {
              headers: {
                Authorization: 'Bearer ' + BigComToken
              }
            }
          )
          .then((res) => {
            const responseToCheck =
            res.data.data.site.search.searchProducts.products;
            const transformData = (response) => {
              return response.edges.map((edge) => {
                const node = edge.node;
                return {
                  ...node,
                  id: node.entityId,
                  name: node.name,
                  price: node.prices.price.value,
                  reviews_rating_sum: node.reviews.edges.reduce(
                    (sum, review) => sum + review.node.rating,
                    0
                  ),
                  pricecurrencyCode: node.prices.price.currencyCode,
                  options: node.productOptions.edges.map((optionEdge) => ({
                    ...optionEdge,
                    product_id: node.entityId,
                    name: optionEdge.node.displayName,
                    display_name: optionEdge.node.displayName,
                    type:
                      optionEdge.node.displayName === "Color"
                        ? "radio_buttons"
                        : "dropdown",
                    option_values: optionEdge.node.values.edges.map(
                      (valueEdge) => ({
                        id: valueEdge.node.entityId,
                        label: valueEdge.node.label,
                        value_data: null,
                        is_default: false,
                      })
                    ),
                  })),
                  reviews_count: node.reviews.edges.length,
                  images: node.images.edges.map((image) => ({
                    id: image.node.entityId,
                    url_thumbnail: image.node.urlOriginal,
                  })),
                };
              });
            };
            const transformedData = transformData(responseToCheck);
            setPopularProducts(transformedData);
          })
          .catch((er) => {
            console.error(er);
            setPopularProducts([]);
          });
      };

      // useEffect(() => {
      //   if(BigComToken) {
      //    const categoriesByGql = async () => {
      //      await axios
      //        .get(`${process.env.REACT_APP_API_PATH}categoriesList?BigComToken=${BigComToken}`)
      //        .then((res) => {
      //         let categoryList = res?.data?.site?.categoryTree[0]?.children
      //         const updatedCategories = catgeoriesBlockData?.map((category) => {
      //           const matchedData = categoryList?.find((item) => item.name === category.name);
      //           return {
      //             ...category,
      //             image: matchedData?.image?.urlOriginal || category?.image, 
      //           };
      //         });
              
      //         setCatgeoriesBlockData(updatedCategories);

      //       });
      //    };
      //    categoriesByGql();
      //   }
      //  }, [BigComToken]);

  useEffect(() => {
    if(!isEmpty(BigComToken)){
      getBestSellingProducts()
      getPopularProducts()
    }
  }, [count,BigComToken, defaultCurrency])

  const renderProducts = (product, type) => {
   return  <section className={classes.fiveProdcutList}>
   <h2>{type === 'popular' ? `Popular this week` : 'BestSeller products'}</h2>
   <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 w-100 ${classes.renderProductsDiv}`}>
   <Row className={classes.fiveProdcutListProduct}>{product.map((item, index) => {
       const inStockVariants = item?.variants?.edges?.map((item)=>item.node).filter((item)=>item.inventory.isInStock===true)
       let newVarients = inStockVariants?.map((item)=> {
          return item?.options?.edges.filter((item)=>item.node.displayName==='Size')[0].node.values.edges[0].node.label 
       })
       let sizes=Array.from(new Set(newVarients))
       
         let reviewsRating = item.reviews_rating_sum / item.reviews_count.toFixed(1)
         let reviewHalfRating = 0
       _.isNaN(parseInt(reviewsRating))
         ? reviewsRating=0
         : reviewsRating=reviewsRating;
       let halfRating = !_.isNaN(parseInt(reviewsRating)) && !_.isInteger(reviewsRating);
       halfRating ? reviewHalfRating=1 : reviewHalfRating=0;
       
       return (
           <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 ${classes.product}`} key={index}>
             <div className={classes.productContainer}>
               <Link to={`/product_details/${item.id}`}
                 className={classes.productLink}
               >
                       <img
                         className="d-block w-100"
                         src={item.images[0].url_thumbnail}
                         alt={`Slide ${index}`}
                       />
               </Link>
               <div className={classes.addTowishList}>
                 <button style={{
                   backgroundColor: WishListIds?.includes(item.id) ? "#eb2675" : "#fff",
                   color: WishListIds?.includes(item.id) ? "#fff" : "#333"
                 }}
                 onClick={() => {
                   if (isNaN(customerId)) {
                     navigator(LoginPath,{state:{from: location.pathname + location.search}});
                     localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
                    //  showErrorToast("You must login first!")
                   } else {
                    //  wishListIds?.includes(item.id)
                    //    ? showErrorToast("Alredy exist")
                    //    : 
                    !WishListIds?.includes(item.id) && addWishlistHandler(item.id, item?.variants);
                   }
                 }}>
                   {WishListIds?.includes(item.id) ? <FaHeart/> :<FaRegHeart />} {WishListIds?.includes(item.id) ? "WISHLISTED":"WISHLIST"}
                 </button>
                 <Link to={`/product_details/${item.id}`}>
                   <p>
                     <b>Size</b>:{" "}
                     {sizes.map((item, index) => {
                       return <span key={index}>{item}{index===sizes.length-1?'':', '}</span>;
                     })}
                   </p>
                 </Link>
               </div>
             </div>
             <Link to={`/product_details/${item.id}`}>
               <p className={classes.productName}>{item.name}</p>
               <div className={classes.price}>
                 <div>
                   <Link>
                     {item.prices.basePrice.currencyCode==="INR"?"₹":"$"}{item.prices.basePrice.value}
                   </Link>
                   <Link>
                     {item.pricecurrencyCode==="INR"?"₹":"$"}{item.price}
                   </Link>
                 </div>
                 <div className={classes.stars}>
                   <span className={classes.startRating}>
                       {Array.from(
                         { length: parseInt(reviewsRating) },
                         (_, index) => (
                           <TiStarFullOutline className={classes.starIcon}
                             fill="#EB2675"
                             key={index}
                           />
                         )
                       )}
                       {Array.from({ length: reviewHalfRating }, (_, index) => (
                         <TiStarHalfOutline className={classes.starIcon}
                           fill="#EB2675"
                           key={index}
                         />
                       ))}
                       {Array.from(
                         {
                           length: 5 - reviewHalfRating - parseInt(reviewsRating),
                         },
                         (_, index) => (
                           <TiStarOutline className={classes.starIcon} fill="#EB2675"  key={index} />
                         )
                       )}
                   </span>
                 </div>
               </div>
             </Link>
             <div></div>
           </div>
       );
     })}
     </Row>
     </div>
   <Link to={type === 'popular' ? `/new_arrivals` : '/best_selling'} className={classes.btn2}>View All</Link>
 </section>
  } 

  const carouselData = [
    { 
      normal: "../../images/home-banner-1.jpg", 
      small: "../../images/home-slide-small-1.jpg"
     },
     { 
      normal: "../../images/home-banner-3.jpg", 
      small: "../../images/home-banner-3.jpg"
     },
     { 
      normal: "../../images/blog-feature-banner.jpg", 
      small: "../../images/blog-feature-banner.jpg"
     },
  ];
  
  return (
    <Fragment>
      {/* <AdBanner /> */}
      <main className={`${classes.main} ${classes.primaryPadding} ${classes.homepage}`}>
        <section>
        <Carousel
          interval = {3000}
          fade={true}
          controls={false}
          indicators={true}
          pause={false} className={`${classes.homePageSlider}`}
        >
          {carouselData?.map((item,index)=>(
             <Carousel.Item className={classes.carouselItem} key={index}>
             <img src={smallScreen ? item.small : item.normal} alt={`Slide ${index}`} />
             </Carousel.Item>
          ))

        }
        </Carousel>            
        </section>
        <Container className={classes.homepageContainer}>
          {/* <section className={classes.categoriesLinks}>
            <a href="#" >Dresses</a>
            <a href="#">Tops</a>
            <a href="#">Bottoms</a>
            <a href="#">Sweaters</a>
            <a href="#">Accessories</a>
            <a href="#">Jackets</a>
          </section> */}
          <section className={classes.catgeoriesBlock}>
            <div className={classes.blockSection}>
              <div className={classes.large}>
                <img src={catgeoriesBlockData[0]?.image} className={classes.categoriesLargeImg} />
                <div className={classes.categoryDetailsSection}>
                  <div className={classes.categoryDetails}>
                    <p className={classes.heading}>{catgeoriesBlockData[0].title}</p>
                  </div>
                  <a className={classes.btn1Link} href={`${ProductsPath}?category=${encodeURIComponent(catgeoriesBlockData[0]?.title)}`}><button className={classes.btn1}>Shop Now</button></a>
                </div>
              </div>
              <div className={classes.small}>
                <img src={catgeoriesBlockData[1]?.image} className={classes.categoriesSmallImg} />
                <div className={classes.categoryDetailsSection}>
                  <div className={classes.categoryDetails}>
                    <p className={classes.heading}>{catgeoriesBlockData[1].title}</p>
                  </div>
                  <a className={classes.btn1Link} href={`${ProductsPath}?category=${encodeURIComponent(catgeoriesBlockData[1]?.title)}`}><button className={classes.btn1}>Shop Now</button></a>
                </div>
              </div>
            </div>
            <div className={classes.blockSection}>
              <div className={classes.small}>
                <img src={catgeoriesBlockData[2]?.image} className={classes.categoriesSmallImg} />
                <div className={classes.categoryDetailsSection}>
                  <div className={classes.categoryDetails}>
                    <p className={classes.heading}>{catgeoriesBlockData[2].title}</p>
                  </div>
                  <a className={classes.btn1Link} href={`${ProductsPath}?category=${encodeURIComponent(catgeoriesBlockData[2]?.title)}`}><button className={classes.btn1}>Shop Now</button></a>
                </div>
              </div>
              <div className={classes.large}>
                <img src={catgeoriesBlockData[3]?.image} className={classes.categoriesLargeImg} />
                <div className={classes.categoryDetailsSection}>
                  <div className={classes.categoryDetails}>
                    <p className={classes.heading}>{catgeoriesBlockData[3].title}</p>
                  </div>
                  <a className={classes.btn1Link} href={`${ProductsPath}?category=${encodeURIComponent(catgeoriesBlockData[3]?.title)}`}><button className={classes.btn1}>Shop Now</button></a>
                </div>
              </div>
            </div>
          </section>
          {renderProducts(bestSelling, 'best-seling')}
          <section className={classes.specificCollection}>
            <div className={classes.content}>
              <h2>The White Dress</h2>
              <p>Step into the season all dressed in white with textures of eyelet and lace or ruffles and bows for the perfect monochromatic look.</p>
              <Link to={`/products?selectedColor=White`} className={classes.btn2} >View All White Dresses</Link>
            </div>
            <div className={classes.specificCollectionImageWrapper}>
              <img src="../../images/white-dress-collection-image.jpg" className={classes.specificCollectionImage} />
            </div>
          </section>
          {renderProducts(popularProducts, 'popular')}
          <section className={`${classes.specificCollection} ${classes.reverseSection}`}>
            <div className={classes.content}>
              <h2>The Black Dress</h2>
              <p>Step into the season all dressed in black with textures of eyelet and lace or ruffles and bows for the perfect monochromatic look.</p>
              <Link to={`/products?selectedColor=Black`} className={classes.btn2}>View All Black Dresses</Link>
            </div>
            <div className={classes.specificCollectionImageWrapper}>
              <img src="../../images/black-dress-collection-image.jpg" className={classes.specificCollectionImage} />
            </div>
          </section>
        </Container>
        {/* <CustomersReview /> */}
      </main>
    </Fragment>
  );
};

export default HomeNew;
