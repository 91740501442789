import React, { useState } from "react";
import classes from "./Help.module.css";
import Accordion from "react-bootstrap/Accordion";
import { userDetailsStore } from "../../store/GlobalUserData";
import { useMobileScreen } from "../../utils/isMobileScreen";
import PersonHelpIcon from "../../Svgs/PersonHelpIcon";
import HelpFaq from "../../components/help/HelpFaq";
import {
  cancellationFaqData,
  helpData,
  ProfilePath,
  shippingFaqData,
} from "../../constant/constant";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Help() {
  const [activeTab, setActiveTab] = useState(1);
  let screen = useMobileScreen();
  const [search, setSearch] = useState("");
  const navigate = useNavigate()
  const searchInputHandler = (e) => {
    setSearch(e.target.value);
  };
  const searchHandler = (e) => {
    sessionStorage.setItem("search", search);
  };
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Help Center - Upvan Fashion</title>
        <meta name="description" content="Help Center - Upvan Fashion" />
      </Helmet>
      <div className={classes.helpHeadingWrapper}>
        <div className={classes.helpTitleWrapper}>
          <p className={classes.heading}>Help Center</p>
          <p className={classes.name}>We are here to help you</p>
        </div>
        <div className={classes.helpHeaderWrapper}>
          <div className={classes.helpHeader}>
            <div className={classes.searchWrapper}>
              {/* <span>
                <i className="pe-7s-search"></i>
              </span>
              <input
                type="text"
                name="search"
                placeholder="Search..."
                onChange={searchInputHandler}
                onKeyDown={(e) => e.key === "Enter" && searchHandler()}
              /> */}
            </div>
            <div className={classes.HeaderAccountBtn}>
              <PersonHelpIcon />
              <div className={classes.accountWrapper}>
                <p>Manage your account</p>
                <Link to={ProfilePath}>ACCOUNT</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {screen ? (
        <div className="helpAccordion">
          <Accordion
            defaultActiveKey={JSON.stringify(activeTab)}
            activeKey={JSON.stringify(activeTab)}
          >
            {helpData?.map((item, index) => (
              <Accordion.Item eventKey={JSON.stringify(item.id)} key={index}>
                <Accordion.Header
                  className={classes.accordionHeader}
                  onClick={(e) => activeTab === item.id ? setActiveTab("") : setActiveTab(item.id)}
                >
                  {item.title}
                </Accordion.Header>
                <Accordion.Body>
                  <HelpFaq data={item?.data} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      ) : (
        <div className={classes.tabContainer}>
          <div className={classes.tab}>
            {helpData?.map((item, index) => (
              <button
                className={classes.tablinks}
                key={index}
                onClick={() => setActiveTab(item.id)}
                style={{
                  color: item.id === activeTab ? "#eb2675" : "#000",
                  fontWeight: item.id === activeTab && 600,
                  borderBottom:
                    item.id === activeTab
                      ? "1px solid #eb2675"
                      : "1px solid #dbdbdb",
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className={classes.tabDetailsContainer}>
            <div style={{ width: "100%" }}>
              {activeTab == 1 && <HelpFaq data={shippingFaqData} />}
              {activeTab == 2 && <HelpFaq data={cancellationFaqData} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Help;
