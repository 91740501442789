import React, { useState, useEffect, Fragment } from "react";
import classes from "./CartSteps.module.css";
import clsx from "clsx";
import ShoppingBag from "../ShoppingBag/ShoppingBag";
import axios from "axios";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { addToCartCountStore } from "../../../store/CartCountStore";
import Loader from "../../Loader/Loader";
import { scrollToTop } from "../../CommonComponents/CommonHelperFunctions";
import AddressCard from "../../addressCard/AddressCard";
import { getBigComToken, getUserId } from "../../../utils/getUserId";
import {
  CartPath,
  cartSteps,
  HomePath,
  ProductsPath,
} from "../../../constant/constant";
import AddAddressModal from "../../profile/AddressesTab/AddAddressModal/AddAddressModal";
import PaymentsPage from "./PaymentsPage";
import { useLocation, useNavigate } from "react-router-dom";
import { createRazorpayOrder } from "../../../utils/paymentUtils";
import CouponsPage from "./CouponsPage";
import ApplyCouponModal from "./ApplyCouponModal";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import ApplyGiftModal from "./ApplyGiftModal";
import { Alert } from "react-bootstrap";

export default function CartSteps() {
  const [cartDetails, setCartDetails] = useState();
  const { userDetails, setUserDetails } = userDetailsStore();
  const [loading, setLoading] = useState(false);
  const { addToCartCount, setAddToCartCount, setItemIdsinCart } =
    addToCartCountStore();
  const [appliedCoupon, setAppliedCoupon] = useState(false)
  console.log(appliedCoupon, cartDetails?.coupons)
  const [removeItem, setRemoveItem] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isAddAddressesModal, setIsAddAddressesModal] = useState(false);
  const [addressesData, setAddressesData] = useState([]);
  const [address, setAddresses] = useState({});
  const [deafultAddress, setDeafultAddresses] = useState({});
  const userId = getUserId();
  const navigate = useNavigate();
  const [render, reRender] = useState(0);
  const { state } = useLocation();
  const [items, setItems] = useState([]);
  const [saleProductTotal, setSaleProductTotal] = useState(0);
  const [discountProductTotal, setDiscountProductTotal] = useState(0);
  const [currency, setCurrency] = useState("₹");
  const BigComToken = getBigComToken();
  const [allGifts, setGifts] = useState([]);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [availableToUse, setAvailToUse] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [btnAnimation, setBtnAnimation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [giftCode, setGiftCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [productDetails, setProductDetails] = useState();
  const [productVarientsDetails, setProductVarientsDetails] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [couponsList, setCouponsList] = useState([]);
  const [giftsBelongToYou, setGiftsBelongToYou] = useState([])
  const [selectedCartProduct, setSelectedCartProduct] = useState({});
  const [paymentMethodData, setPaymentMethodData] = useState({
    paymentMethod: "upi",
    paymentTitle: "Pay via UPI",
  });
  const [isNoMethodSelected,
    setIsNoMethodSelected] = useState(false);

  useEffect(() => {
    if (userDetails?.notes === "") {
      navigate(HomePath);
    }
  }, [userDetails]);

  const getGiftCertificateById = async() =>{
    await axios
    .get(
      `${process.env.REACT_APP_API_PATH}getGiftCertificateById?to_email=${userDetails?.email}`
    )
    .then((res) => {
      setGiftsBelongToYou(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getCouponsData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}getAllCoupons?checkoutId=${userDetails?.notes}`
      )
      .then((res) => {
        setCouponsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllGiftCertificates = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PATH}getAllGiftCertificates`)
      .then((res) => {
        setGifts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCartDetails = async () => {
    setLoading(true);
    setRemoveItem(false);
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}checkoutDetails?checkoutId=${userDetails?.notes}`
      )
      .then((res) => {
        setCartDetails(res?.data.data.cart);
        if(res?.data.data.coupons.length !== 0) {
           setAppliedCoupon(true)
           setCouponCode(res?.data.data.coupons[0].code)
        }
        // res?.data.data.cart && handleDeleteCoupon(res?.data.data.coupons)
        // setAddToCartCount(res?.data?.data?.line_items?.physical_items?.length);
        // setItemIdsinCart(res?.data?.data?.line_items?.physical_items.map(v=>v.variant_id))
        setLoading(false);
        let productsIds = res?.data?.data?.cart.line_items?.physical_items.map(
          (item) => item.product_id
        );
        productsIds = productsIds.filter(
          (item, index) => productsIds.indexOf(item) === index
        );
        productsVarients(productsIds);
        fetchVariantData(
          res?.data?.data?.cart.line_items?.physical_items.map(
            (v) => v.variant_id
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    scrollToTop();
    if(userDetails?.notes !== ""){
      getCouponsData();
      getAllGiftCertificates();
      getGiftCertificateById()
      getCartDetails();
    }
  }, [removeItem]);
  
  const handleDeleteCoupon = () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_PATH}deleteCoupon`, {
        coupon_code: couponCode,
        checkoutId: cartDetails?.id,
      })
      .then((res) => {
        setCouponCode('')
        getCartDetails()
        setAppliedCoupon(false)
        setLoading(true)
      })
      .catch((err) => {
        setLoading(false);
        setAppliedCoupon(false)
        setLoading(true)
      });
  };

   const handleApplyGiftCode = () => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}addGiftToCheckout`, {
        giftCertificateCode: giftCode,
        checkoutId: cartDetails.id,
      })
      .then((res) => {
        console.log(res.data);
        if(res.data.status === 400) {
         showErrorToast(res.data.title) 
        }else {
          showSuccessToast("Coupon Applied Successfully!");
          setAppliedCoupon(true)
        }
      
        setCartDetails(res?.data.data.cart);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleApplyCoupon = () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_PATH}applyCoupon`, {
        coupon_code: couponCode,
        checkoutId: cartDetails.id,
      })
      .then((res) => {
        console.log(res.data);
        if(res.data.status === 400 || res.data.status === 422) {
         showErrorToast(res.data.title) 
        }else {
          showSuccessToast("Coupon Applied Successfully!");
        }
        setAppliedCoupon(true)
        setCartDetails(res?.data.data.cart);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
        setAppliedCoupon(false)
        setLoading(false)
      });
  };

  useEffect(() => {
    if (activeTab == 1 && !deafultAddress) {
      setBtnAnimation(true);
      setErrorMessage("Please add Address");
    } else if (activeTab == 2 && isNoMethodSelected) {
      setBtnAnimation(true);
      setErrorMessage("Please select any payment method");
    } else {
      setBtnAnimation(false);
      setErrorMessage("");
    }
  }, [activeTab, checkedItems, deafultAddress, isNoMethodSelected]);

  const fetchAddressData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_PATH}address?id=${userId}`)
      .then((res) => {
        setAddressesData(res?.data);
        setDeafultAddresses(res?.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    !isNaN(userId) && fetchAddressData();
  }, [isAddAddressesModal, render]);

  const handleCupons = () => {
    let availCoupon = couponsList.find((v) =>
      v.code.includes(couponCode.toUpperCase())
    );
    let availProducts = cartDetails.line_items.physical_items.map(
      (v) => v.product_id
    );
    let checkForApplicable = availCoupon?.applies_to.ids.filter((v) =>
      availProducts.includes(v)
    );
    if (availCoupon) {
      if (checkForApplicable.length !== 0) {
        handleApplyCoupon();
      } else {
        showErrorToast("This coupon is not applicable!");
      }
    } else {
      showErrorToast("This coupon code is invalid!");
    }
  };

  const handleApplyGift = () => {
    let checkForEligibleGift = giftsBelongToYou.filter(v => giftCode.includes(v.code))
    console.log(checkForEligibleGift)
    // if (checkForEligibleGift.length) {
      handleApplyGiftCode();
    // } else {
    //   showErrorToast("This Gift is invalid!");
    // }
  };

  const deleteAddress = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_PATH}deleteAddress`, { id: id })
      .then((res) => {
        setLoading(false);
        // showSuccessToast("Address Removed Successfully!");
        reRender((prev) => prev + 1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handlePreviousStep = (id) => {
    if (activeTab > id) {
      setActiveTab(id);
    }
  };

  const handleClose = () => {
    setIsAddAddressesModal(false);
    setAddresses({});
  };

  useEffect(() => {
    let data = cartDetails?.line_items?.physical_items?.map((item) => item.id);
    setItems(data);
    cartDetails?.currency?.code === "INR" ? setCurrency("₹") : setCurrency("$");
  }, [cartDetails]);

  useEffect(() => {
    let data = cartDetails?.line_items?.physical_items;
    let newData = data?.map((item) => {
      const matchData = productVarientsDetails
        .find((ele) => ele.id == item.variant_id)
        ?.option_values?.filter(
          (item) => item.option_display_name == "Size"
        )[0]?.label;
      return { ...item, size: matchData ? matchData : null };
    });
    // data = newData?.filter(
    //   (item) => checkedItems[item.id] === true
    // );
    // let discountData = cartDetails?.discounts?.filter(
    //   (item) => checkedItems[item.id] === true
    // );
    data = newData;
    let dataForGifts = cartDetails?.line_items?.gift_certificates
    let saleGiftsTotal = dataForGifts?.reduce((previous, current) => {
         return previous + current.amount;
       }, 0);
    let discountData = cartDetails?.discounts;
    let saleTotal = data?.reduce((previous, current) => {
      return previous + current.sale_price * current.quantity;
    }, 0);

    let discountDataTotal = discountData?.reduce((previous, current) => {
      return previous + current.discounted_amount;
    }, 0);

    let selectedData = data?.map((item) => {
      return {
        product_id: item.product_id,
        quantity: item.quantity,
        variant_id: item.variant_id,
      };
    });
    let productIds = data?.map((item) => {
      return item.id;
    });
    let cartData = {
      cartId: cartDetails?.id,
      productIds: productIds,
      isCustomerUpdate:
        cartDetails?.line_items?.physical_items?.length == data?.length
          ? true
          : false,
    };
    if (activeTab === 3) {
      setCartItems(data);
    }
    setSelectedCartProduct(cartData);
    setSelectedProduct(selectedData);
    setSaleProductTotal(saleTotal+saleGiftsTotal);
    setDiscountProductTotal(discountDataTotal);
  }, [checkedItems, cartDetails, activeTab]);

  useEffect(() => {
    let data = cartDetails?.line_items?.physical_items;
    let newData = data?.map((item) => {
      const matchData = productVarientsDetails
        .find((ele) => ele.id == item.variant_id)
        ?.option_values?.filter(
          (item) => item.option_display_name == "Size"
        )[0]?.label;
      return { ...item, size: matchData ? matchData : null };
    });
    setCartItems(newData);
  }, [cartDetails, productVarientsDetails]);

  const fetchVariantData = async (productsIds) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}getVariantByids?ids=${productsIds}`
      )
      .then((res) => {
        setProductVarientsDetails(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const productsVarients = async (productsIds) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}productsVarientsById?productsIds=${productsIds}&BigComToken=${BigComToken}`
      )
      .then((res) => {
        setProductDetails(res?.data);
      });
  };

  const billAddressUpdate = async () => {
    let addressData = { ...deafultAddress, email: userDetails.email };
    await axios
      .post(`${process.env.REACT_APP_API_PATH}updateBillingAddress`, {
        data: addressData,
        cartId: cartDetails?.id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.wizard}>
      {loading && <Loader />}
      {isAddAddressesModal && (
        <AddAddressModal handleClose={handleClose} address={address} />
      )}
      <>
        <div className={classes.wizardInner}>
          <ul className={clsx(classes.nav, classes.navTabs)} role="tablist">
            {cartSteps?.map((item, index) => (
              <li
                role="presentation"
                className={
                  item.id === activeTab ? classes.active : classes.disabled
                }
                key={index}
              >
                <div onClick={() => handlePreviousStep(item.id)}>
                  <i className={item.icon}></i>
                  <p>{item.title}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <form role="form" action="indexphysicalItem.html" className="login_box">
          <div className={classes.tab_content} id="main_form">
            <div className={`${classes.tab_pane} ${classes.active}`}>
              {activeTab === 1 && (
                <div className={classes.address_left}>
                  <AddressCard
                    addressesData={addressesData}
                    deleteAddress={deleteAddress}
                    setAddresses={(value) => {
                      setAddresses(value);
                      setDeafultAddresses(value);
                    }}
                    setIsAddAddressesModal={setIsAddAddressesModal}
                  />
                  <div className={classes.verticalDivider}/>
                </div>
              )}
              {/* {activeTab === 3 && (
                <PaymentsPage
                  paymentMethodData={paymentMethodData}
                  setPaymentMethodData={setPaymentMethodData}
                  setIsNoMethodSelected={setIsNoMethodSelected}
                />
              )} */}
              {activeTab === 2 && (
                <ShoppingBag
                  reviewPage="review"
                  physicalItem={cartItems}
                  setRemoveItem={setRemoveItem}
                  checkedItems={checkedItems}
                  items={items}
                  setCheckedItems={setCheckedItems}
                  currency={currency}
                />
              )}
              <div className={classes.shopping_bag_content}>
                {activeTab < 3 && <div className={classes.right}>
                  {/* {activeTab === 2 && deafultAddress && (
                    <div className={classes.adress_and_change_address}>
                      <div className={classes.default_address_section}>
                        <div className={classes.address_with_pincode}>
                          <p className={classes.deafult_address}>
                            Selected Address
                          </p>
                          <p className={classes.name_and_pincode}>
                            {deafultAddress?.first_name}{" "}
                            {deafultAddress?.last_name}
                          </p>
                        </div>
                        <p className={classes.address}>
                          {" "}
                          {deafultAddress?.address1}, {deafultAddress?.address2}
                          , {deafultAddress?.city},{" "}
                          {deafultAddress?.state_or_province},{" "}
                          {deafultAddress?.postal_code}
                        </p>
                      </div>
                      <a
                        onClick={() => setActiveTab(1)}
                        className={classes.changeAddress}
                      >
                        Want to Change Address?
                      </a>
                    </div>
                  )} */}
                  {appliedCoupon ? <Alert variant="success" onClose={() => {
                    handleDeleteCoupon()
                  }} dismissible>Coupon Applied!</Alert> : <div className={classes.couponCodeApplySection}>
                    <p>Coupons</p>
                    <div className={classes.couponBox}>
                      <div className={classes.couponInputBoxSection}>
                        <i className={"pe-7s-ticket"}></i>
                        <input
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          placeholder="Enter Code here.."
                          className={classes.couponInputBox}
                        />
                      </div>
                      <button
                        type="button"
                        disabled={couponCode.length===0}
                        onClick={handleCupons}
                        className={classes.btn1}
                      >
                        Apply
                      </button>
                    </div>
                    <button
                      type="button"
                      className={`btn btn-primary ${classes.addNewAddress}`}
                      data-toggle="modal"
                      onClick={() => setOpenCouponModal(true)}
                      data-target="#exampleModal"
                    >
                      More Coupons
                    </button>
                  </div>}
                  {/* <div className={classes.couponCodeApplySection}>
                    <p>Gift Certificates</p>
                    <div className={classes.couponBox}>
                      <div className={classes.couponInputBoxSection}>
                        <i className={"pe-7s-ticket"}></i>
                        <input
                          value={giftCode}
                          onChange={(e) => setGiftCode(e.target.value)}
                          placeholder="Enter Code here.."
                          className={classes.couponInputBox}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={handleApplyGift}
                        className={classes.btn1}
                      >
                        Apply Gift
                      </button>
                    </div>
                    <button
                      type="button"
                      className={`btn btn-primary ${classes.addNewAddress}`}
                      data-toggle="modal"
                      onClick={() => setOpenGiftModal(true)}
                      data-target="#exampleModal"
                    >
                      More gifts belongs to you
                    </button>
                  </div> */}
                  <div className={classes.price_detil_section}>
                    <p className={classes.heading}>
                      Price Details
                      (Total{" "}
                      {
                        Object.values(checkedItems)?.filter(
                          (value) => value === true
                        ).length
                      }{" "}
                      items)
                    </p>
                    <div className={classes.price_info}>
                      <div className={classes.two_values}>
                        <p>Total MRP</p>
                        <p className={classes.price}>
                          {currency}
                          {Math.round(saleProductTotal)}
                        </p>
                      </div>
                      <div className={classes.two_values}>
                      <p>{cartDetails?.coupons.length!==0?`Discount on MRP (Coupon: ${cartDetails?.coupons[0].code})`:"Discount on MRP"}</p>
                        <p className={`${classes.price} ${classes.green}`}>
                          {currency}
                          {Math.round(discountProductTotal)}
                        </p>
                      </div>
                      <div className={classes.two_values}>
                        <p>Shipping Fee</p>
                        <p className={`${classes.price} ${classes.green}`}>
                          FREE
                        </p>
                      </div>
                    </div>
                    <div className={classes.total_amount}>
                      <p>Total Amount</p>
                      <p className={classes.price}>
                        {currency}
                        {Math.round(saleProductTotal) - discountProductTotal || 0}
                      </p>
                    </div>
                    <button
                      type="button"
                      className={`${classes.default_btn} ${classes.next_step} ${
                        btnAnimation && classes.horizontalShake
                      }`}
                      disabled={loading}
                      onClick={() => {
                        if (
                          !btnAnimation &&
                          activeTab < 2 &&
                          !isNoMethodSelected
                        ) {
                          setActiveTab(activeTab + 1);
                        }
                        if (activeTab == 1) {
                          billAddressUpdate();
                        }
                        if (activeTab == 2) {
                          setActiveTab(activeTab + 1);
                          let addressData = {
                            ...deafultAddress,
                            email: userDetails.email,
                          };
                          let totalAmount =
                            saleProductTotal - discountProductTotal || 0;
                          let line_items =
                            cartDetails?.line_items?.physical_items?.map(
                              (item) => {
                                return {
                                  item_id: item.id,
                                  quantity: item.quantity,
                                };
                              }
                            );
                          setLoading(true);
                          createRazorpayOrder(
                            totalAmount,
                            currency == "₹" ? "INR" : "USD",
                            paymentMethodData?.paymentMethod,
                            paymentMethodData?.paymentTitle,
                            userDetails,
                            addressData,
                            cartDetails,
                            line_items,
                            setUserDetails,
                            setAddToCartCount,
                            setLoading,
                            setActiveTab
                          );
                        }
                      }}
                    >
                      {activeTab == 3 && !isNoMethodSelected
                        ? "Place Order"
                        : "Continue to next step"}
                    </button>
                    {errorMessage && (
                      <p className={classes.errorMessage}>{errorMessage}</p>
                    )}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </form>
      </>
      {activeTab === 2 && (
        <a
          onClick={() => navigate(ProductsPath)}
          className={classes.addMoreItems}
        >
          <i className="pe-7s-cart" style={{ fontSize: "26px" }}></i>Want to add
          more items?
        </a>
      )}
      {openCouponModal && (
        <ApplyCouponModal
          getCartDetails={getCartDetails}
          cartDetails={cartDetails}
          appliedCoupon={appliedCoupon}
          setAppliedCoupon={setAppliedCoupon}
          setLoading={setLoading}
          couponCodeList={couponsList}
          setCloseCoupon={() => setOpenCouponModal(false)}
          open={openCouponModal}
        />
      )}
      {openGiftModal && (
        <ApplyGiftModal
          setCartDetails={setCartDetails}
          cartDetails={cartDetails}
          giftsBelongToYou={giftsBelongToYou}
          setCloseCoupon={() => setOpenGiftModal(false)}
          open={openCouponModal}
        />
      )}
    </div>
  );
}
