import React, { Fragment, useEffect, useState } from "react";
import classes from "./AddressesTab.module.css";
import AddressTabIcon from "../../../Svgs/AddressTabIcon";
import AddAddressModal from "./AddAddressModal/AddAddressModal";
import { getUserId } from "../../../utils/getUserId";
import axios from "axios";
import { showSuccessToast } from "../../../utils/toastUtils";
import Loader from "../../Loader/Loader";
import AddressCard from "../../addressCard/AddressCard";

function AddressesTab() {
  const [isAddAddressesModal, setIsAddAddressesModal] = useState(false);
  const [addressesData, setAddressesData] = useState([]);
  const [address, setAddresses] = useState({});
  const [loading, setLoading] = useState(false);
  const [render, reRender] = useState(0);
  const userId = getUserId();
  const fetchAddressData = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_PATH}address?id=${userId}`)
      .then((res) => {
        setAddressesData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  };
  useEffect(() => {
    !isNaN(userId) && fetchAddressData();
  }, [isAddAddressesModal,render]);

  const handleClose = () => {
    setIsAddAddressesModal(false);
    setAddresses({});
  };

  const deleteAddress = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_PATH}deleteAddress`,{id:id})
      .then((res) => {
        setLoading(false);
        // showSuccessToast("Address Removed Successfully!");
        reRender((prev) => prev + 1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading && <Loader />}
      {isAddAddressesModal && (
        <AddAddressModal handleClose={handleClose} address={address} />
      )}
      <div className={classes.tabcontent}>
        {addressesData.length == 0 && !loading && <div className={classes.addressesHeader}>
          <AddressTabIcon />
          <p className={classes.heading}>Save your addresses now</p>
          <p className={classes.para}>
            Add your home and office addresses and enjoy faster checkout.
          </p>
        </div>}
        <div className={classes.addressSection}>
          <AddressCard
            addressesData={addressesData}
            deleteAddress={deleteAddress}
            setAddresses={(value) => {
              setAddresses(value)
            }}
            setIsAddAddressesModal={setIsAddAddressesModal}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default AddressesTab;
