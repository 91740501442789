import React from "react";

function WishlistNavIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <mask
        id="mask0_716_562"
        style={{maskType:"alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_716_562)">
        <path
          d="M19.9987 34.9998L17.582 32.8332C14.7765 30.3054 12.457 28.1248 10.6237 26.2915C8.79036 24.4582 7.33203 22.8123 6.2487 21.354C5.16536 19.8957 4.40842 18.5554 3.97786 17.3332C3.54731 16.1109 3.33203 14.8609 3.33203 13.5832C3.33203 10.9721 4.20703 8.7915 5.95703 7.0415C7.70703 5.2915 9.88759 4.4165 12.4987 4.4165C13.9431 4.4165 15.3181 4.72206 16.6237 5.33317C17.9293 5.94428 19.0543 6.80539 19.9987 7.9165C20.9431 6.80539 22.0681 5.94428 23.3737 5.33317C24.6793 4.72206 26.0543 4.4165 27.4987 4.4165C30.1098 4.4165 32.2904 5.2915 34.0404 7.0415C35.7904 8.7915 36.6654 10.9721 36.6654 13.5832C36.6654 14.8609 36.4501 16.1109 36.0195 17.3332C35.589 18.5554 34.832 19.8957 33.7487 21.354C32.6654 22.8123 31.207 24.4582 29.3737 26.2915C27.5404 28.1248 25.2209 30.3054 22.4154 32.8332L19.9987 34.9998ZM19.9987 30.4998C22.6654 28.1109 24.8598 26.0623 26.582 24.354C28.3043 22.6457 29.6654 21.1596 30.6654 19.8957C31.6654 18.6318 32.3598 17.5068 32.7487 16.5207C33.1376 15.5346 33.332 14.5554 33.332 13.5832C33.332 11.9165 32.7765 10.5276 31.6654 9.4165C30.5543 8.30539 29.1654 7.74984 27.4987 7.74984C26.1931 7.74984 24.9848 8.11789 23.8737 8.854C22.7626 9.59012 21.9987 10.5276 21.582 11.6665H18.4154C17.9987 10.5276 17.2348 9.59012 16.1237 8.854C15.0126 8.11789 13.8043 7.74984 12.4987 7.74984C10.832 7.74984 9.44314 8.30539 8.33203 9.4165C7.22092 10.5276 6.66536 11.9165 6.66536 13.5832C6.66536 14.5554 6.85981 15.5346 7.2487 16.5207C7.63759 17.5068 8.33203 18.6318 9.33203 19.8957C10.332 21.1596 11.6931 22.6457 13.4154 24.354C15.1376 26.0623 17.332 28.1109 19.9987 30.4998Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default WishlistNavIcon;
