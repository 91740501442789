import React from "react";
import classes from "./OverviewTab.module.css";
import OrderIcon from "../../../Svgs/OrderIcon";
import WishlistIcon from "../../../Svgs/WishlistIcon";
import SavedCardsIcon from "../../../Svgs/SavedCardsIcon";
import WalletIcon from "../../../Svgs/WalletIcon";
import AddressIcon from "../../../Svgs/AddressIcon";
import ProfileIcon from "../../../Svgs/ProfileIcon";
import { WishListPath } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import ProfileDetailsIcon from "../../../Svgs/ProfileDetailsIcon";
import SavedUPIIcon from "../../../Svgs/SavedUPIIcon";
import { userDetailsStore } from "../../../store/GlobalUserData";

const profileData = [
  {
    id: 2,
    title: "Orders",
    subTitle: "Check your order status",
    icon: <OrderIcon />,
  },
  {
    id: 9,
    title: "Wishlist",
    subTitle: "View all your wishlist items",
    icon: <WishlistIcon />,
  },
  {
    id: 4,
    title: "Profile Details",
    subTitle: "Change your profile details",
    icon: <ProfileDetailsIcon />,
  },
  // {
  //   id: 5,
  //   title: "Saved Cards",
  //   subTitle: "Save your cards for faster checkout",
  //   icon: <SavedCardsIcon />,
  // },
  {
    id: 8,
    title: "Addresses",
    subTitle: "Saved addresses for hassle-free checkout",
    icon: <AddressIcon />,
  },
];

function OverviewTab({ setActiveTab }) {
  const navigate = useNavigate();
  const { userDetails } = userDetailsStore();

  return (
    <div className={classes.tabcontent}>
      <div className={classes.editProfileSection}>
        <div className={classes.imageAndProfileDetails}>
          <ProfileIcon />
          <div className={classes.profileDetails}>
            <p className={classes.profileName}>
              {userDetails?.first_name} {userDetails?.last_name}
            </p>
            <p>{userDetails.phone}</p>
            <p>{userDetails.email}</p>
          </div>
        </div>
        <button
          className={classes.editProfile}
          onClick={() => {
            setActiveTab(4);
          }}
        >
          Edit Profile
        </button>
      </div>
      <div className={classes.overviewList}>
        {profileData.map((item, index) => (
          <div  
            className={classes.list}
            key={index}
            onClick={() => {
              item.id == 9 ? navigate(WishListPath) : setActiveTab(item.id);
            }}
          >
            {item.icon}
            <p className={classes.overviewListHeading}>{item.title}</p>
            <p className={classes.overviewListPera}>{item.subTitle}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OverviewTab;
