import React, { useEffect, useState } from "react";
import classes from "./ShoppingBag.module.css";
import axios from "axios";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { IoClose } from "react-icons/io5";
import { showErrorToast } from "../../../utils/toastUtils";
import { CouponIcon } from "../../../Svgs/CouponIcon";

function ShoppingBag({
  physicalItem,
  setRemoveItem,
  // checkedItems,
  // items,
  // setCheckedItems,
  currency,
  reviewPage,
  setCartItems
}) {
  const { userDetails, setUserDetails } = userDetailsStore();
  // const [checkAll, setCheckAll] = useState(false);
  const deleteCartItem = async (itemId) => {
    const items = {
      cartId: userDetails?.notes,
      itemId: itemId,
      itemCount: physicalItem?.length,
    };
    await axios
      .post(`${process.env.REACT_APP_API_PATH}deleteCartItem`, items)
      .then((res) => {
        if (res?.data?.emptyCart) {
          updateCustomer();
          setCartItems([])
        }
        setRemoveItem(true);
      })
      .catch((err) => console.log(err));
  };

  const updateCustomer = async () => {
    const payload = {
      id: userDetails.id,
      notes: "",
    };
    await axios
      .post(`${process.env.REACT_APP_API_PATH}updatecustomer`, payload)
      .then((res) => {
        setUserDetails(res?.data[0]);
        setRemoveItem(true);
      })
      .catch((err) => console.log(err.response.data.error));
  };

  // const handleCheckboxChange = (item) => {
  //   setCheckedItems((prev) => ({
  //     ...prev,
  //     [item]: !prev[item],
  //   }));
  // };

  // const handleCheckAllChange = () => {
  //   const newCheckAll = !checkAll;
  //   setCheckAll(newCheckAll);
  //   const newCheckedItems = items.reduce(
  //     (acc, item) => ({ ...acc, [item]: newCheckAll }),
  //     {}
  //   );
  //   setCheckedItems(newCheckedItems);
  // };

  // useEffect(() => {
  //   let data =
  //     Object.values(checkedItems).length > 0 &&
  //     Object.values(checkedItems).length === physicalItem?.length &&
  //     Object.values(checkedItems)?.every((value) => value === true);
  //   data ? setCheckAll(true) : setCheckAll(false);
  // }, [checkedItems]);

  const handleChangeQuantity = async (e, data) => {
    let payload = {
      line_item: {
        quantity: parseInt(e.target.value),
        product_id: data.product_id,
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}updateCartItem?cartID=${userDetails?.notes}&itemID=${data.id}`,
        payload
      )
      .then((res) => {
        // showSuccessToast(res?.data?.message)
        setRemoveItem(true);
      })
      .catch((err) => showErrorToast(err?.response?.data?.message));
  };
  return (
    <div className={classes.shopping_bag_content}>
      <div className={classes.left}>
        {/* {!reviewPage && (
          <div className={classes.checkbox_with_text}>
            <input
              type="checkbox"
              checked={checkAll}
              onChange={handleCheckAllChange}
            />
            <p>
              <b>
                {
                  Object.values(checkedItems)?.filter((value) => value === true)
                    .length
                }
                /{items?.length}
              </b>{" "}
              Itmes Selected
            </p>
          </div>
        )} */}
        <div className={classes.physicalItemsWrapper}>
          {physicalItem?.map((item, index) => {
            if(item.type == "gifts"){
              return <div key={index}>
              <div className={classes.carts_sction}>
                <div className={classes.cart}>
                  <div className={classes.left}>
                    {/* {!reviewPage && (
                      <input
                        type="checkbox"
                        checked={checkedItems[item.id]}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    )} */}
                    <CouponIcon/>
                  </div>
                  {!reviewPage && (
                    <div className={classes.close}>
                      <IoClose onClick={() => deleteCartItem(item.id)} />
                    </div>
                  )}
                  <div className={classes.right}>
                    <p className={classes.title}>{item.name}</p>
                    <div className={classes.select_section}>
                      <div
                        className={`${classes.select_options} ${
                          reviewPage ? classes.review_page : ""
                        }`}
                      >
                        <p>Send to:</p>
                        <span style={{ fontWeight: "bolder" }}>
                          {item?.recipient.email}
                        </span>
                      </div>
                      <div
                        className={`${classes.select_options} ${
                          reviewPage ? classes.review_page : ""
                        }`}
                      >
                        <p>From:</p>
                          <span style={{ fontWeight: "bolder" }}>
                            {item?.sender.name}
                          </span>
                      </div>
                    </div>
                    <div className={classes.price_section}>
                      <p className={classes.actual_price}>
                        {currency}
                        {Math.round(item.amount)}
                      </p>
                    </div>
                    <div className={classes.return_section}>
                      <i className={`${classes.back} pe-7s-back`}></i>
                      <p>
                        <b>Message</b> {item.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {index + 1 < physicalItem.length && (
                <div className={classes.borderDiv} />
              )}
            </div>
            }else{
            return (
              <div key={index}>
                <div className={classes.carts_sction}>
                  <div className={classes.cart}>
                    <div className={classes.left}>
                      {/* {!reviewPage && (
                        <input
                          type="checkbox"
                          checked={checkedItems[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      )} */}
                      <img src={item.image_url} />
                    </div>
                    {!reviewPage && (
                      <div className={classes.close}>
                        <IoClose onClick={() => deleteCartItem(item.id)} />
                      </div>
                    )}
                    <div className={classes.right}>
                      <p className={classes.title}>{item.name}</p>
                      <div className={classes.select_section}>
                        <div
                          className={`${classes.select_options} ${
                            reviewPage ? classes.review_page : ""
                          }`}
                        >
                          <p>Size:</p>
                          <span style={{ fontWeight: "bolder" }}>
                            {item?.size}
                          </span>
                          {/* <select name="size" id="size">
                        {item?.sizes?.map((ele, i) => (
                          <option
                            value={ele.variantId}
                            key={i}
                            selected={item.variant_id == ele.id}
                          >
                            {ele.sizeType}
                          </option>
                        ))}
                      </select> */}
                        </div>
                        <div
                          className={`${classes.select_options} ${
                            reviewPage ? classes.review_page : ""
                          }`}
                        >
                          <p>Quantity:</p>
                          {!reviewPage ? (
                            <select
                              name="quantity"
                              id="quantity"
                              value={item.quantity}
                              onChange={(e) => handleChangeQuantity(e, item)}
                            >
                              {Array.from(
                                { length: item.quantity },
                                (_, index) => (
                                  <option value={index + 1}>{index + 1}</option>
                                )
                              )}
                            </select>
                          ) : (
                            <span style={{ fontWeight: "bolder" }}>
                              {item.quantity}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={classes.price_section}>
                        <p className={classes.actual_price}>
                          {currency}
                          {Math.round(item.sale_price)}
                        </p>

                        {item.discount_amount > 0 && (
                          <>
                            <p className={classes.original_price}>
                              {currency}
                              {item.original_price}
                            </p>
                            <p className={classes.offer_discount}>
                              {currency}
                              {item.discount_amount} OFF
                            </p>
                          </>
                        )}
                      </div>
                      <div className={classes.return_section}>
                        <i className={`${classes.back} pe-7s-back`}></i>
                        <p>
                          <b>7 days</b> return available
                        </p>
                      </div>
                      <div className={classes.delivery_date_section}>
                        <i className={`${classes.check} pe-7s-check`}></i>
                        <p>
                          Delivery between<b> 17/04/2024 - 25/04/2024</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {index + 1 < physicalItem.length && (
                  <div className={classes.borderDiv} />
                )}
              </div>
            );
          }
          })}
        </div>
      </div>
      <div className={classes.verticalDivider}/>
    </div>
  );
}
export default ShoppingBag;
