import React from "react";

function CartNavIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <mask
        id="mask0_716_568"
        style={{maskType:"alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_716_568)">
        <path
          d="M11.668 36.6668C10.7513 36.6668 9.96658 36.3404 9.3138 35.6877C8.66102 35.0349 8.33464 34.2502 8.33464 33.3335C8.33464 32.4168 8.66102 31.6321 9.3138 30.9793C9.96658 30.3266 10.7513 30.0002 11.668 30.0002C12.5846 30.0002 13.3694 30.3266 14.0221 30.9793C14.6749 31.6321 15.0013 32.4168 15.0013 33.3335C15.0013 34.2502 14.6749 35.0349 14.0221 35.6877C13.3694 36.3404 12.5846 36.6668 11.668 36.6668ZM28.3346 36.6668C27.418 36.6668 26.6332 36.3404 25.9805 35.6877C25.3277 35.0349 25.0013 34.2502 25.0013 33.3335C25.0013 32.4168 25.3277 31.6321 25.9805 30.9793C26.6332 30.3266 27.418 30.0002 28.3346 30.0002C29.2513 30.0002 30.036 30.3266 30.6888 30.9793C31.3416 31.6321 31.668 32.4168 31.668 33.3335C31.668 34.2502 31.3416 35.0349 30.6888 35.6877C30.036 36.3404 29.2513 36.6668 28.3346 36.6668ZM10.2513 10.0002L14.2513 18.3335H25.918L30.5013 10.0002H10.2513ZM8.66797 6.66683H33.2513C33.8902 6.66683 34.3763 6.95155 34.7096 7.521C35.043 8.09044 35.0569 8.66683 34.7513 9.25016L28.8346 19.9168C28.5291 20.4724 28.1194 20.9029 27.6055 21.2085C27.0916 21.5141 26.5291 21.6668 25.918 21.6668H13.5013L11.668 25.0002H31.668V28.3335H11.668C10.418 28.3335 9.47352 27.7849 8.83464 26.6877C8.19575 25.5904 8.16797 24.5002 8.7513 23.4168L11.0013 19.3335L5.0013 6.66683H1.66797V3.3335H7.08464L8.66797 6.66683Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default CartNavIcon;
